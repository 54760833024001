<template>
	<div>
		<template>
		  <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
		    <el-tab-pane label="代收黑名单" name="first">
				
					<div class="queryBox mb20">
						<el-button size="mini" type="primary" icon="el-icon-plus" @click="openDialog()">新增代收黑名单</el-button>
						
					</div>
				
				<el-table :max-height="height" :data="tableData" border stripe v-loading="loading" style="width: 700px; max-height: 500px;"  size="mini">
					<el-table-column prop="name" label="代收姓名" align="center"></el-table-column>
					<el-table-column prop="" :label="$t('main.passagewayMenu.operation')" align="center">
						<template slot-scope="scope">
							  <el-button size="mini" @click="delAccount(scope.row)">删除</el-button>
							  <!-- <el-button size="mini" @click="openSetFee(scope.row)">设置手续费</el-button> -->
							  
						</template>
					</el-table-column>
				</el-table>
				<div class="flexX pageBox mt20">
					<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
				</div>
			</el-tab-pane>
		    <el-tab-pane label="代付黑名单" name="second">
				
				<div class="queryBox mb20">
					<el-button size="mini" type="primary" icon="el-icon-plus" @click="openDialog()">新增代付黑名单</el-button>
					
				</div>
				<el-table :max-height="height" :data="repayTableData" border stripe v-loading="loading" style="width: 700px; max-height: 500px;"  size="mini">
					<el-table-column prop="name" label="代付姓名" align="center"></el-table-column>
					<el-table-column prop="" :label="$t('main.passagewayMenu.operation')" align="center">
						<template slot-scope="scope">
							  <el-button size="mini" @click="delAccount(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="flexX pageBox mt20">
					<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
				</div>
			</el-tab-pane>
		  </el-tabs>
		</template>
		
		
		<div>
			<el-dialog class="dialog" top="200px" :title="title" :visible.sync="dialogVisible" @close="close"
			 :close-on-click-modal="false" center>
				<div class="formBox" v-loading="dialogloading">
					<div class="mb20">
						<el-form :label-position="labelPosition" label-width="80px">
							<el-form-item label="姓名">
								<el-input v-model="blackName" placeholder="请输入姓名"></el-input>
							</el-form-item>
							
						</el-form>
					</div>
					<div class="textCenter"><el-button type="primary" size="mini" @click="addAccount()">确定</el-button></div>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				tableData:[],
				item:{},
				title:'设置黑名单',
				dialogVisible:false,
				dialogloading:false,
				labelPosition:'right',
				fee:'',
				height:0,
				activeName: 'first',
				repayTableData: [],
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				type: 1,
				blackName: '',
			}
		},
		created() {
			this.getBlackList()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 200
			})
		},
		methods:{
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val
				this.page = 1
				this.getBlackList()
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val
				this.getBlackList()
			},
			//查看列表
			getBlackList() {
				console.log(this.activeName)
				if(this.activeName == 'first'){ // 代收
					this.repayTableData = []
					this.type = 1;
				}
				if(this.activeName == 'second'){ // 代付
					this.tableData = []
					this.type = 2;
				}
				let data = {
					type: this.type,
					page: this.page,
					size: this.pageSize,
				}
				// this.loading = true
				this.$api.getBlackList(data).then(res => {
					this.loading = false
					if (res.status == 1) {
						this.total = res.data.total
						if(this.type == 1){
							this.tableData = res.data.data
						}
						if(this.type == 2){
							this.repayTableData = res.data.data
						}
						console.log(this.total)
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
			
				}).catch(error => {
					this.loading = false
				})
			},
			addName(){
				let data = {
					name:this.blackName,
					type:this.type,
				}
			
				this.loading = true
				this.$api.addBlackName(data).then(res => {
					this.loading = false
					// console.log(res)
					if (res.status === 1) {
						this.$message({
							type: 'info',
							message: this.$t('main.common.successConfirm')
						});
						this.getBlackList()
					} else {
						this.$message({
							type: 'info',
							message: res.msg
						});
					}
			
				}).catch(error=>{
					this.loading = false
				})
					
			},
			handleClick(tab, event){
				this.page = 1
				this.total = 0
				if(this.activeName == 'first'){ // 代收
					this.repayTableData = []
					this.getBlackList()
				}
				if(this.activeName == 'second'){ // 代付
					this.tableData = []
					this.getBlackList()
				}
			},
			openDialog(){
				this.dialogVisible = true
			},
			addAccount() {
				//新增币种
				if (isEmpty(this.blackName, '请输入姓名')) {
					return
				}
				
				let data = {
					name: this.blackName,
					type: this.type,
				}
				this.dialogloading = true
				this.$api.addBlackName(data).then(res => {
					// console.log(res)
					this.dialogloading = false
					if (res.status === 1) {
						this.$message({
							type: 'success',
							message: res.msg
						});
						this.close();
						this.getBlackList()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						});
					}
					// console.log(res)
				}).catch(error => {
					this.dialogloading = false
				})
			},
			close(){
				this.dialogVisible = false
				this.blackName = ''
			},
			delAccount(item){
				
				let data = {
					id:item.id,
				}
				this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
					confirmButtonText: this.$t('main.common.submit'),
					cancelButtonText: this.$t('main.common.cancel'),
					type: 'warning'
				}).then(() => {
					this.loading = true
					this.$api.delBlackName(data).then(res => {
						this.loading = false
						// console.log(res)
						if (res.status === 1) {
							this.getBlackList()
							this.$message(this.$t('main.common.successConfirm'));
						} else {
							this.$message({
								type: 'info',
								message: res.msg
							});
						}
				
					}).catch(error=>{
						this.loading = false
					})
					
				}).catch(() => {
					this.$message({
						type: 'info',
						message: this.$t('main.common.cancelConfirm')
					});
				});
			},
		},
	}
</script>

<style>
</style>
